import * as React from "react"
import { graphql } from "gatsby"
import { Accordion } from "../components/research/components/Accordion"
import ArticleBox from "../components/research/components/article-box"
import { parsePapers } from "../components/research/utils"
import { parseData } from "../components/research/utils/query"
import introImage from "../images/img17.svg"
import {
  PaddedContent,
  PageHeading,
  PageHeadingParagraph,
  PageWrapper,
} from "../components/modern/layout"

export const query = graphql`
  query QueryPapers {
    file(
      sourceInstanceName: { eq: "papers" }
      name: { eq: "index" }
      extension: { eq: "yaml" }
    ) {
      internal {
        content
      }
    }
    allFile(
      filter: {
        sourceInstanceName: { eq: "papers" }
        extension: { ne: "yaml" }
      }
    ) {
      edges {
        node {
          publicURL
          name
          extension
        }
      }
    }
  }
`

const Research = ({ data }) => {
  const { papers, tweagers, files } = parseData(data)

  const articleBoxProps = parsePapers(papers, tweagers, files)

  return (
    <PageWrapper title="Research" pathname="/research">
      <PageHeading title="Tweag's Academic Research" imageSrc={introImage}>
        <PageHeadingParagraph>
          Engaging in scholarly research supports Tweag&apos;s mission to
          improve the art of compositional software engineering. We at Tweag
          learn from the best &#8212; and contribute our own new discoveries
          &#8212; by publishing and participating in top-tier venues for
          computer science research. Sharing knowledge is the way we build a
          world where software everywhere is more correct, more maintainable,
          and more performant.
        </PageHeadingParagraph>
        <PageHeadingParagraph>
          We employ researchers with strong academic backgrounds and ask them to
          keep pushing the envelope. Their sole focus is to identify future
          directions for automation and programming languages. Tweagers are
          working on safety through linear types and dependent types, best
          practices, application performance and other innovations to improve
          developer and scientist experience.
        </PageHeadingParagraph>
        <PageHeadingParagraph>
          <strong>TOTAL PAPERS: {papers.length}</strong>
        </PageHeadingParagraph>
      </PageHeading>
      <PaddedContent>
        <Accordion>
          <ArticleBox {...articleBoxProps} />
        </Accordion>
      </PaddedContent>
    </PageWrapper>
  )
}

export default Research
